import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Paragraph, Paragraphs } from "../components/atoms/typography";
import { Page } from "../components/templates/page/page";
import { InfoPanel } from "../components/templates/page/panel/info-panel";
import { Section } from "../components/templates/page/section/section";
import { SectionHero, SectionHeroImageCss } from "../components/templates/page/section/section-hero";
import { SectionInfo, SectionInfoQuoteText } from "../components/templates/page/section/section-info";
import { SectionIntroHeading, SectionIntroLayout } from "../components/templates/page/section/section-intro";

const CirclePage = () => (
  <Page title={"H2FA Circular"}>
    <SectionHero
      title={"Helping to create a circular world"}
      image={
        <StaticImage
          src={`../assets/images/hero/hero-circular.png`}
          style={SectionHeroImageCss}
          placeholder="blurred"
          alt=""
        />
      }
    />
    <Section>
      <SectionIntroLayout
        left={<SectionIntroHeading maxWidth={500}>The h2fa circle</SectionIntroHeading>}
        right={
          <Paragraphs>
            <Paragraph maxWidth={600}>H2FA uses elements it takes from nature and returns to nature.</Paragraph>
            <Paragraph maxWidth={600}>
              H2FA uses sustainable resources to power industry and return resources to the environment. Nothing is
              abused, everything is enhanced. This is H2FA’s low impact circular model.
            </Paragraph>
          </Paragraphs>
        }
      />
    </Section>
    <SectionInfo>
      <SectionInfoQuoteText
        quote={"We need an informed public and an educated workforce to make the energy transition happen."}
        author={"Dr. Rebecca Yee, Education Officer"}
      />
    </SectionInfo>
    <Section>
      <InfoPanel
        image={
          <StaticImage src={`../assets/images/abstract/abstract-swirl-blue-curved.png`} placeholder="blurred" alt="" />
        }
        title={"Education hub"}
        subtitle={"Coming soon"}
        description={[
          "Offering hands-on experience and interactive virtual programs, H2FA's training and knowledge hub will provide an outstanding user experience which can be tailored to suit each age group and audience.",
          "More than citizen science, our programs offer opportunities for the community at large to learn about clean energy technology. Engaging with our community helps H2FA learn as well as teach - and better understand how circular technologies can fit the land and history of each H2FA site.",
        ]}
        pointsAlign={"top"}
        points={["Hands-on experience", "Virtual programs", "Community engagement"]}
      />
    </Section>
  </Page>
);

export default CirclePage;
